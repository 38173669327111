<script lang="ts">
    import { getContext, onMount } from 'svelte';
    import { inertia } from '@inertiajs/svelte';
    import confetti from 'canvas-confetti';
    import * as Tooltip from "$lib/components/ui/tooltip";
    import Dropzone from 'dropzone';
    import './settings.css';
    import axios from 'axios';
    import { LottiePlayer } from '@lottiefiles/svelte-lottie-player';
    import { Input } from '$lib/components/ui/input';
    import { Button } from '$lib/components/ui/button';
    import * as Dialog from "$lib/components/ui/dialog";
    import {ArrowRight} from "lucide-svelte";
    const settingsContext = getContext('settingsContext');
    const { authUser, data, default_user_theme, cookie_app_theme } = settingsContext;
    import {Checkbox} from "$lib/components/ui/checkbox";


    $: activeTab = data.activeTab;
    $: submitTab = false;
    $: gesture = '';
    $: finalStep = false;

    let myDropzone = null;
    let uploadedFiles = [];
    let contentConsent = false;

    const initUploader = () => {
        const selector = '.dropzone';
        if (data.verification?.status == 'rejected' || data.verification?.status == 'pending') {
            console.log('working');
            // Check if Dropzone is already attached to the element
            myDropzone = new Dropzone(selector, {
                url: `${app.baseUrl}/my/settings/verify/upload`,
                paramName: 'file',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                },
                maxFilesize: mediaSettings.max_file_upload_size,
                addRemoveLinks: true,
                dictRemoveFile: 'x',
                acceptedFiles: mediaSettings.allowed_file_extensions,
                dictDefaultMessage: trans('Drop files here to upload'),
                autoDiscover: false,
                previewsContainer: '.dropzone-previews',
                autoProcessQueue: true,
                parallelUploads: 1,
            });

            myDropzone.on('addedfile', (file) => {
                updatePreviewElement(file, true);
            });

            myDropzone.on('success', (file, response) => {
                uploadedFiles.push(response.assetSrc);
                file.upload.assetSrc = response.assetSrc;
            });

            myDropzone.on('removedfile', function (file) {
                removeAsset(file.upload.assetSrc);
            });

            myDropzone.on('error', (file, errorMessage) => {
                if (typeof errorMessage.errors !== 'undefined') {
                    Object.values(errorMessage.errors).forEach((error) => {
                        launchToast('danger', trans('Error'), error);
                    });
                } else {
                    const message = errorMessage.message || errorMessage;
                    launchToast('danger', trans('Error'), message);
                }
                myDropzone.removeFile(file);
            });
        }
    };

    const removeAsset = (file) => {
        if (!submitTab) {
            fetch(`${app.baseUrl}/my/settings/verify/upload/delete`, {
                method: 'POST',
                body: JSON.stringify({ assetSrc: file }),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                },
            })
                .then((response) => {
                    if (response.ok) {
                        launchToast('success', trans('Success'), trans('Attachment removed.'));
                    } else {
                        launchToast('danger', trans('Error'), trans('Failed to remove the attachment.'));
                    }
                })
                .catch((error) => {
                    console.error('Error removing asset:', error);
                    launchToast('danger', trans('Error'), trans('Failed to remove the attachment.'));
                });
        }
    };

    const updatePreviewElement = (file, isAdded) => {
        // Implement this function if needed
    };

    onMount(() => {
        if (data.verification?.status == 'rejected' || data.verification?.status == 'pending') {
            initUploader();
        }
    });

    let showDialog: boolean = false;
    let showContinueBtn: boolean = false;
    let signatureLink: string = '';
    let yousign;

    async function triggerSignatureProcess() {
        showDialog = true;
        try {
            const response = await axios.post('/my/verification/content-provider-agreement', {
                firstName: CPA_FirstName,
                lastName: CPA_LastName
            });
            signatureLink = response.data.signature_link;
            yousign = new Yousign({
                signatureLink: signatureLink,
                iframeContainerId: 'sign_test',
                isSandbox: true,
                iFrameAttributes: {},
            });
            yousign.onSuccess((data) => {
                showContinueBtn = true;
                setTimeout(() => {
                    confetti({
                        particleCount: 100,
                        spread: 70,
                        origin: { y: 0.6 }
                    });
                }, 600);
            });
        } catch (error) {
            console.error('Error triggering signature process:', error);
        }
    }



    function updateProfile(event = null) {
        submitTab = true;
        if(event) {
            event.preventDefault();
        }
        document.querySelector(`#spinner`).style.display = 'inline-block';

        const placeBidButton = document.querySelector(`#submissionBtn`);
        placeBidButton.querySelector('.buttonText').style.display = 'none';

        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['X-CSRF-TOKEN'] = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content');

        axios
            .post(`/my/settings/verify/save?active=${activeTab}`)
            .then((response) => {
                placeBidButton.disabled = false;
                placeBidButton.querySelector('.spinner').style.display = 'none';
                placeBidButton.querySelector('.buttonText').style.display = 'inline-block';

                launchToast('success', trans('Success'), response.data.success);
                // Check if Dropzone instance exists
                if (myDropzone) {
                    myDropzone.destroy();
                    initUploader();
                }
                submitTab = false;
                if (activeTab === 'step2') {
                    gesture = response.data.gesture.title;
                    console.log(response.data.gesture.title);
                }
                if (activeTab === 'step3') {
                    progressBtnShow = false;
                }
                if (activeTab === 'step4'){
                    finalStep=true;
                }
                activeTab = response.data.activeTab;
            })
            .catch((error) => {
                placeBidButton.disabled = false;
                placeBidButton.querySelector('.spinner').style.display = 'none';
                placeBidButton.querySelector('.buttonText').style.display = 'inline-block';
                if (error.response && error.response.status === 422) {
                    const errors = error.response.data.error;
                    launchToast('danger', trans('Error'), trans(errors));
                } else {
                    launchToast('danger', trans('Error'), trans(error.response.data.error));
                }
                submitTab = false;
            });
    }

    let tabTitles = [{ tabTitle: 'ID/Passport Front & Back', tab: 'step1' },
        { tabTitle: 'Selfie with ID/Passport', tab: 'step2' },
        { tabTitle: 'Selfie with Gesture', tab: 'step3' },
        { tabTitle: 'Content Provider Agreement', tab: 'step4' }]
    let uploadTabs = ["step1", "step2", "step3"];
    let progressBtnShow = true;
    let CPA_FirstName = null;
    let CPA_LastName = null;


</script>

<svelte:head>
    <script src="https://cdn.yousign.tech/iframe-sdk-1.6.0.min.js" integrity="sha384-/7MD1voOOzWVz7FmgeMwmmd1DO85Mo0PkkxdYd9j2wDGzGDGRG/phgnL0c9Xyy52" crossorigin="anonymous"></script>
    <link rel="stylesheet" href="https://unpkg.com/dropzone@5/dist/min/dropzone.min.css" type="text/css" />
</svelte:head>


<Dialog.Root bind:open={showDialog}>
    <Dialog.Content class="w-[95vw] flex flex-col items-center justify-center max-w-[95vw] h-[95vh] max-h-[95vh]">
        <Dialog.Header>
            <Dialog.Title>Content Provider Agreement</Dialog.Title>
            <Dialog.Description>
                Please review and sign the Content Provider Agreement below. Once you're done, a button will appear to continue.
            </Dialog.Description>
        </Dialog.Header>
        <div id="sign_test" class="w-full h-[calc(95vh-130px)]"></div>
        <Button class={`${showContinueBtn ? "" : "hidden "}-mt-10 px-6 py-2 max-w-md`} on:click={() => {showDialog = false; updateProfile();}}>Submit Verification <ArrowRight class="ml-1"></ArrowRight></Button>
    </Dialog.Content>
</Dialog.Root>

<div class="verifyUi">
    <h2 class="text-base font-bold">Your Verification Level: {data['verification']?.verification_level}</h2>
    <div class="levelIcons flex justify-between">
        <span class="block">
            {#if cookie_app_theme == ''}
                {#if default_user_theme == 'dark'}
                    <img src="/svg/mailDark.svg" alt="" />
                {:else}
                    <img src="/svg/mail.svg" alt="" />
                {/if}
            {:else if cookie_app_theme == 'dark'}
                <img src="/svg/mailDark.svg" alt="" />
            {:else}
                <img src="/svg/mail.svg" alt="" />
            {/if}
        </span>
        <span class="block {data.verification?.status !== 'verified' ? 'opacity-25' : ''}">
            {#if cookie_app_theme == ''}
                {#if default_user_theme == 'dark'}
                    <img src="/svg/idcardDark.svg" alt="" />
                {:else}
                    <img src="/svg/idcard.svg" alt="" />
                {/if}
            {:else if cookie_app_theme == 'dark'}
                <img src="/svg/idcardDark.svg" alt="" />
            {:else}
                <img src="/svg/idcard.svg" alt="" />
            {/if}
        </span>
        <span class="block {data.verification?.status !== 'verified' ? 'opacity-25' : ''}">
            {#if cookie_app_theme == ''}
                {#if default_user_theme == 'dark'}
                    <img src="/svg/homeDark.svg" alt="" />
                {:else}
                    <img src="/svg/home.svg" alt="" />
                {/if}
            {:else if cookie_app_theme == 'dark'}
                <img src="/svg/homeDark.svg" alt="" />
            {:else}
                <img src="/svg/home.svg" alt="" />
            {/if}
        </span>
        <span class="block {data.verification?.status !== 'verified' ? 'opacity-25' : ''}">
            {#if cookie_app_theme == ''}
                {#if default_user_theme == 'dark'}
                    <img src="/svg/starDark.svg" alt="" />
                {:else}
                    <img src="/svg/star.svg" alt="" />
                {/if}
            {:else if cookie_app_theme == 'dark'}
                <img src="/svg/starDark.svg" alt="" />
            {:else}
                <img src="/svg/star.svg" alt="" />
            {/if}
        </span>
    </div>
    <div class="levelIcons relative mx-1 block pb-2 pt-3">
        <div
            class="levelProgress absolute top-1/2 h-1 w-full rounded {cookie_app_theme == null
                ? default_user_theme == 'dark'
                    ? 'darkProgress'
                    : 'bg-gray-200'
                : cookie_app_theme == 'dark'
                  ? 'darkProgress'
                  : 'bg-gray-200'}"
        >
            <span
                class="block h-1 {cookie_app_theme == null
                    ? default_user_theme == 'dark'
                        ? 'bg-white'
                        : 'bg-black'
                    : cookie_app_theme == 'dark'
                      ? 'bg-white'
                      : 'bg-black'}"
                style="width: {data.verification?.status === 'verified' ? '100%' : '0%'}"
            ></span>
        </div>
        <div class="levelDots absolute top-1/4 flex w-full justify-between">
            <span
                class="levelDot h-4 w-4 {cookie_app_theme == null
                    ? default_user_theme == 'dark'
                        ? 'bg-white'
                        : 'bg-black'
                    : cookie_app_theme == 'dark'
                      ? 'bg-white'
                      : 'bg-black'} block rounded-full"
            ></span>
            <span
                class="levelDot h-4 w-4 {data.verification?.status === 'verified'
                    ? cookie_app_theme == null
                        ? default_user_theme == 'dark'
                            ? 'bg-white'
                            : 'bg-black'
                        : cookie_app_theme == 'dark'
                          ? 'bg-white'
                          : 'bg-black'
                    : cookie_app_theme == null
                      ? default_user_theme == 'dark'
                          ? 'darkProgress'
                          : 'bg-gray-200'
                      : cookie_app_theme == 'dark'
                        ? 'darkProgress'
                        : 'bg-gray-200'}  block rounded-full"
            ></span>
            <span
                class="levelDot h-4 w-4 {data.verification?.status === 'verified'
                    ? cookie_app_theme == null
                        ? default_user_theme == 'dark'
                            ? 'bg-white'
                            : 'bg-black'
                        : cookie_app_theme == 'dark'
                          ? 'bg-white'
                          : 'bg-black'
                    : cookie_app_theme == null
                      ? default_user_theme == 'dark'
                          ? 'darkProgress'
                          : 'bg-gray-200'
                      : cookie_app_theme == 'dark'
                        ? 'darkProgress'
                        : 'bg-gray-200'} block rounded-full"
            ></span>
            <span
                class="levelDot h-4 w-4 {data.verification?.status === 'verified'
                    ? cookie_app_theme == null
                        ? default_user_theme == 'dark'
                            ? 'bg-white'
                            : 'bg-black'
                        : cookie_app_theme == 'dark'
                          ? 'bg-white'
                          : 'bg-black'
                    : cookie_app_theme == null
                      ? default_user_theme == 'dark'
                          ? 'darkProgress'
                          : 'bg-gray-200'
                      : cookie_app_theme == 'dark'
                        ? 'darkProgress'
                        : 'bg-gray-200'} block rounded-full"
            ></span>
        </div>
    </div>
    {#if data.verification && !finalStep && data.verification?.rejectionReason && data.verification?.status === 'rejected'}
        <div class="alert alert-warning font-weight-bold mt-2 text-white" role="alert">
            Your previous verification attempt was rejected for the following reason: "{data['verification']
            .rejectionReason}"
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    {/if}
    {#if data.verification && !finalStep && (data.verification?.status == 'rejected' || data.verification?.status == 'pending')}
        <h2 class="mt-4 text-base font-bold">Verify Further:</h2>
        <form class="verify-form flex flex-col" on:submit="{updateProfile}">
            {#if !data.verification || data.verification?.verification_level == 0 || (data.verification && data.verification?.status !== 'verified' && data.verification?.status !== 'pending')}
                <div class="inline-border-tabs mt-3">
                    <nav class="nav nav-pills nav-justified">
                        {#each tabTitles as tab}
                            <a class="nav-item nav-link" class:active="{activeTab === tab['tab']}">
                                <div class="d-flex align-items-center justify-content-center">
                                    {tab['tabTitle']}
                                </div>
                            </a>
                        {/each}
                    </nav>
                </div>
                {#if activeTab === 'step1'}
                    <span class="my-6">Upload clear photos of your ID/Passport (<b>front and back</b> side)</span>
                {:else if activeTab === 'step2'}
                    <span class="my-6">Upload a selfie of you holding your ID/Passport next to your face</span>
                {:else if activeTab === 'step3'}
                    <span class="my-6">Upload a selfie with the following gesture:<br><i>{gesture}</i></span>
                {:else if activeTab === "step4"}
                    <span class="mt-6 mb-2">1. Give Consent to BuyMyNudes on using your Content</span>
                    <div class="mb-2 mt-2 flex flex-row items-start">
                        <Checkbox bind:checked={contentConsent} class="mr-2 mt-1"  id="content-provider-agreement" />
                        <span class="text-sm cursor-pointer" on:click={() => {contentConsent=!contentConsent;}}>
                        I have read and agree to the <a href="/uploaded-content-consent" target="_blank" class="underline text-indigo-600 hover:text-indigo-800">Content Consent Agreement</a>. I understand that by checking this box, I'm giving explicit consent for BuyMyNudes to use my uploaded content as described in the agreement.
                        </span>
                    </div>
                    <span class="mt-4 mb-4">2. Enter your first and last name - same as on your identification document, and sign the Content Provider Agreement</span>
                    <div class="flex flex-col gap-x-2">
                        <div class="flex flex-row gap-x-2">
                            <div>
                                <span class="text-sm">First Name</span>
                                <Input bind:value={CPA_FirstName} placeholder="First Name"></Input>
                            </div>
                            <div>
                                <span class="text-sm">Last Name</span>
                                <Input bind:value={CPA_LastName} placeholder="Last Name"></Input>
                            </div>
                        </div>
                        <Tooltip.Root>
                            <Tooltip.Trigger>
                                <Button class="w-full mt-4" disabled={!contentConsent} on:click={triggerSignatureProcess}>Continue</Button>
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                <p>{contentConsent ? "Launch signing process" : "Agree to our Content Consent Agreement first."}</p>
                            </Tooltip.Content>
                        </Tooltip.Root>
                    </div>
                {/if}
                <div class={`dropzone-previews dropzone w-100 ppl-0 rounded border pb-1 pr-0 pt-1 ${uploadTabs.includes(activeTab) ? "" : "hidden"}`}></div>
                <div class={`flex flex-row-reverse ${progressBtnShow ? "" : "hidden"}`}>
                    <Button
                        class="btn mt-2 rounded !bg-bmn-500 text-white hover:!bg-bmn-600"
                        id="{'submissionBtn'}"
                        type="submit"
                    >
                        <span id="{'spinner'}" class="spinner" style="display: none">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path
                                    d="M15 4v7h5V4h-5zM4 15h5v5H4v-5zM4 4v5h5V4H4zm11 11h5v5h-5v-5z"
                                    fill="currentColor"
                                ></path>
                                <path d="M8 8v8h8V8H8zm7 7H9V9h6v6z" fill="currentColor"></path>
                            </svg>
                        </span>
                        <span class="buttonText">{activeTab === 'step4' ? 'Submit' : 'Next'}</span>
                    </Button>
                </div>
            {/if}
            {#if authUser.email_verified_at && authUser.birthdate && authUser.verification && authUser.verification.status == 'verified'}
                <p class="mt-3">Your info looks good, you're all set to post new content!</p>
            {/if}
        </form>
    {:else if (data.verification && data.verification?.status === 'requested') || finalStep}
        <div class="flex flex-col items-center pb-3 text-center">
            <div class="relative mb-3 flex h-48 w-64 items-center overflow-hidden">
                {#if cookie_app_theme == ''}
                    {#if default_user_theme == 'dark'}
                        <LottiePlayer
                            src="/animations/verif_processing_dark.json"
                            autoplay="{true}"
                            loop="{true}"
                            controls="{false}"
                            renderer="svg"
                            background="transparent"
                            class="w-100"
                        />
                    {:else}
                        <LottiePlayer
                            src="/animations/verif_pending_light.json"
                            autoplay="{true}"
                            loop="{true}"
                            controls="{false}"
                            renderer="svg"
                            background="transparent"
                            class="w-100"
                        />
                    {/if}
                {:else if cookie_app_theme == 'dark'}
                    <LottiePlayer
                        src="/animations/verif_processing_dark.json"
                        autoplay="{true}"
                        loop="{true}"
                        controls="{false}"
                        renderer="svg"
                        background="transparent"
                        class="w-100"
                    />
                {:else}
                    <LottiePlayer
                        src="/animations/verif_pending_light.json"
                        autoplay="{true}"
                        loop="{true}"
                        controls="{false}"
                        renderer="svg"
                        background="transparent"
                        class="w-100"
                    />
                {/if}
            </div>
            <p class="text-2xl">Your documents are being processed.</p>
            <p>This process can take up to 3 business days</p>
            <a href="/contact">Support</a>
        </div>
    {:else if data.verification && data.verification?.status === 'verified' && !finalStep}
        <div class="flex flex-col items-center pb-3 text-center">
            <div class="relative mb-3 flex h-48 w-32 items-center overflow-hidden">
                <LottiePlayer
                    src="/animations/verified_success.json"
                    autoplay="{true}"
                    loop="{true}"
                    controls="{false}"
                    renderer="svg"
                    background="transparent"
                    class="w-100"
                />
            </div>
            <p class="text-2xl">You are fully verified.</p>
            <a href="/contact">Support</a>
        </div>
    {/if}
</div>

<!-- not allowed popup -->
{#if !authUser.email_verified_at || !authUser.birthdate}
    <div class="beforeVerifying absolute left-1/3 top-1/3 z-10 rounded-lg bg-neutral-800 px-5 py-4">
        <h5
            class="mb-3 font-bold {cookie_app_theme == null
                ? default_user_theme == 'dark'
                    ? ''
                    : 'text-light'
                : cookie_app_theme == 'dark'
                  ? ''
                  : 'text-light'}"
        >
            Before Verifying
        </h5>
        <ul class="m-0 list-none p-0">
            {#if !authUser.birthdate}
                <li>
                    <a href="/my/settings" use:inertia class="flex gap-2 text-red-700 hover:text-red-500">
                        <span class="align-items-center flex">
                            <svg
                                width="10"
                                height="9"
                                viewBox="0 0 10 9"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1 1L8.25 8" stroke="#FF001F" stroke-width="2" stroke-linecap="round"></path>
                                <path d="M8.25 1L1 8" stroke="#FF001F" stroke-width="2" stroke-linecap="round"></path>
                            </svg>
                        </span>
                        Set Date Of Birth
                    </a>
                </li>
            {/if}
            {#if !authUser.email_verified_at}
                <li>
                    <a href="/my/settings" use:inertia class="flex gap-2 text-red-700 hover:text-red-500">
                        <span class="align-items-center flex">
                            <svg
                                width="10"
                                height="9"
                                viewBox="0 0 10 9"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1 1L8.25 8" stroke="#FF001F" stroke-width="2" stroke-linecap="round"></path>
                                <path d="M8.25 1L1 8" stroke="#FF001F" stroke-width="2" stroke-linecap="round"></path>
                            </svg>
                        </span>
                        Confirm Email Address
                    </a>
                </li>
            {/if}
        </ul>
    </div>
{/if}

<style>
    .spinner {
        display: inline-block;
        animation: spin 1s infinite linear;
        /* 1s is the duration, 'infinite' makes it rotate continuously */
    }

    .spinner .ion-icon-wrapper .ion-icon-inner svg {
        color: #550041;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
            /* Starting position */
        }

        to {
            transform: rotate(360deg);
            /* Ending position (360 degrees) */
        }
    }
    :global(#yousign-iframe) {
        width: 100%;
        height: 100%;
    }
    #yousign-iframe{
        width: 100%;
        height: 100%;
    }
</style>
